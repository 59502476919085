import {useOnSnapshotCollection} from "./useDatabase";

export interface ProductException {
  plu: string;
}

export interface ProductExceptionsData {
  [key: string]: ProductException;
}

export function useProductExceptions(userId: string | undefined): ProductExceptionsData | undefined {
  return useOnSnapshotCollection(userId ? "users/" + userId + "/productExceptions" : undefined, true) as ProductExceptionsData;
}