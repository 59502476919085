import { useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";

import { auth } from "../config/firebase";
import { DocumentData  } from "firebase/firestore";
import { signOut, User } from "firebase/auth";

import { createInfo, FormInfo } from "../utils/formUtils";

import {ProviderData, useProviders} from "../hooks/useProviders";
import {CheckoutSystemData, useCheckoutSystems} from "../hooks/useCheckoutSystems";
import {useVendingMachines, useVendingMachinesAll, VendingMachinesData} from "../hooks/useVendingMachines";
import {LoginData, useCredentials} from "../hooks/useCredentials";
import {GroupsData, useGroups} from "../hooks/useGroups";

import MainContainer from "../components/MainContainer";
import ProfileHeader from "../components/profile/ProfileHeader";
import ProfileNavigation from "../components/profile/ProfileNavigation";
import ProfileInfoCards from "../components/profile/ProfileInfoCards";
import FormContainer from "../components/forms/containers/FormContainer";
import PersonalInformationForm, { PersonalInformationFormOut } from "../components/forms/PersonalInformationForm";
import SettingsForm, {QrCodeSettingsFormOut} from "../components/forms/SettingsForm";
import ProviderForm, { ProviderFormOut } from "../components/forms/ProviderForm";
import VendingMachinesForm, {VendingMachinesFormOut} from "../components/forms/VendingMachinesForm";
import GroupsForm, {GroupsFormOut, NewGroupFormOut} from "../components/forms/GroupsForm";
import ProductExceptionsForm, {ExceptionsFormOut, NewExceptionFormOut} from "../components/forms/ProductExceptionsForm";
import {ProductExceptionsData, useProductExceptions} from "../hooks/useProductExceptions";

interface Props {
  pageLoading: boolean;
  firebaseUser: User | null;
  user: DocumentData | null | undefined;
}

const Profile = ({ pageLoading, firebaseUser, user }: Props) => {

  const navigate: NavigateFunction = useNavigate();

  const providers: ProviderData | undefined = useProviders();
  const checkoutSystems: CheckoutSystemData | undefined = useCheckoutSystems();
  const credentials: LoginData | undefined = useCredentials(firebaseUser?.uid);
  const vendingMachinesAll: VendingMachinesData | undefined = useVendingMachinesAll(firebaseUser?.uid);
  const vendingMachines: VendingMachinesData | undefined = useVendingMachines(firebaseUser?.uid, credentials, vendingMachinesAll);
  const groups: GroupsData | undefined = useGroups(firebaseUser?.uid);
  const exceptions: ProductExceptionsData | undefined = useProductExceptions(firebaseUser?.uid);

  const [logoutLoading, setLogoutLoading] = useState<boolean>(false);
  const [actionLoading, setActionLoading] = useState<boolean>(false);

  const [formInfo, setFormInfo] = useState<FormInfo>(createInfo());

  const [navigationValue, setNavigationValue] = useState<number>(0);

  const isLoading: boolean = pageLoading || !providers || !vendingMachines || !credentials || !checkoutSystems || !exceptions;

  const logout = async () => {
    setLogoutLoading(true);
    try {
      await signOut(auth);
      navigate("/login");
    }
    catch (error: any) {
      console.log(error);
      setLogoutLoading(false);
    }
  };

  const handleNavigation = (selected: number) => {
    setFormInfo(createInfo());
    setNavigationValue(selected)
  };

  const updateUserData = async (formData?: any) => {
    setActionLoading(true);
    try {
      const checkData = await formData.check();
      await formData.save(checkData?.vendingMachines, checkData?.isRefresh);
      setFormInfo(createInfo(true, "Einstellungen wurden erfolgreich gespeichert.", 10000));
    }
    catch (error: any) {
      setFormInfo(createInfo(false, error.message));
    }
    finally {
      setActionLoading(false);
    }
  };

  return (
    <MainContainer>
      
      <ProfileHeader
        loading={isLoading}
        actionLoading={logoutLoading}
        onLogout={() => logout()}
      />
      
      <ProfileInfoCards
        loading={isLoading}
        email={firebaseUser?.email}
        userData={user}
        credentials={credentials}
        checkoutSystems={checkoutSystems}
        providers={providers}
      />

      <ProfileNavigation
        loading={isLoading}
        onChange={(value) => handleNavigation(value)}
      />

      <FormContainer
        show={navigationValue === 0}
        headline={"Persönliche Informationen bearbeiten"}
        description="Durch die Angabe von Ihren Identitäts- und Kontaktdaten sind wir in der Lage, Sie zuzuordnen und zu kontaktieren"
        info={formInfo}
      >
        <PersonalInformationForm
          loading={isLoading}
          actionLoading={actionLoading}
          defaultData={user}
          email={firebaseUser?.email}
          onSubmit={(data: PersonalInformationFormOut) => updateUserData(data)}
        />
      </FormContainer>
      {
        !isLoading && (
          <>
            <FormContainer
            show={navigationValue === 1}
            headline={"Verknüpfungen bearbeiten"}
            description="Eine regelmäßige Anpassung sollte nicht notwendig sein. Häufige Änderungen der Verknüpfungen weisen auf Betrugsversuche hin und werden von uns geprüft."
            info={formInfo}
            createForm={false}
            >
              <ProviderForm
                actionLoading={actionLoading}
                defaultData={credentials}
                providers={providers}
                vendingMachinesAll={vendingMachinesAll}
                onSubmit={(data: ProviderFormOut) => updateUserData(data)}
              />
            </FormContainer>

            <FormContainer
            show={navigationValue === 2}
            headline={"Automateninformationen bearbeiten"}
            description="Hier werden alle erkannten Automaten gelistet. Durch das ändern der Automaten-Name wird die Zuordnung durch den Benutzer erleichert."
            info={formInfo}
            >
              <VendingMachinesForm
                vendingMachines={vendingMachines}
                providers={providers}
                groups={groups}
                groupManagement={user?.settings.groupManagement}
                onSubmit={(data: VendingMachinesFormOut) => updateUserData(data)}
                actionLoading={actionLoading}
              />
            </FormContainer>

            <FormContainer
              show={navigationValue === 3}
              headline={"Automatengruppen erstellen und bearbeiten"}
              description="Druch das erstellen von Gruppen können Automaten einfacher und übersichtlicher verwaltet werden. Dies ist vorallem bei größerer Automatenanzahl zu empfehlen."
              info={formInfo}
            >
              <GroupsForm
                actionLoading={actionLoading}
                activated={user?.settings.groupManagement}
                groups={groups}
                vendingMachines={vendingMachines}
                onSubmit={(data: NewGroupFormOut | GroupsFormOut) => updateUserData(data)}
              />
              </FormContainer>

            <FormContainer
              show={navigationValue === 4}
              headline={"Produkt-Ausnahmen erstellen und bearbeiten"}
              description="Druch das erstellen von Produkt-Ausnahmen können Produkte im Datensatz ignoriert werden. Dies ist zum Beispiel bei inkorrekten Artikelnummern vorteilshaft."
              info={formInfo}
            >
              <ProductExceptionsForm
                actionLoading={actionLoading}
                exceptions={exceptions}
                onSubmit={(data: NewExceptionFormOut | ExceptionsFormOut) => updateUserData(data)}
              />
            </FormContainer>

            <FormContainer
              show={navigationValue === 5}
              headline={"Generelle Einstellungen bearbeiten"}
              info={formInfo}
            >
              <SettingsForm
                defaultData={user?.settings}
                checkoutSystems={checkoutSystems}
                onSubmit={(data: QrCodeSettingsFormOut) => updateUserData(data)}
                actionLoading={actionLoading}
              />
            </FormContainer>
          </>
      )
    }
    </MainContainer>
  );
};

export default Profile;
