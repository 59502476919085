import {useState, useEffect, SyntheticEvent } from "react";

import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import {Person, Cloud, Inventory, Settings, Workspaces, ProductionQuantityLimits} from '@mui/icons-material';

import "../../style/ProfileNavigation.css"; 

interface Props {
  loading: boolean;
  onChange: (value: number) => void;
}

const ProfileNavigation = ({ loading, onChange }: Props) => {
  
  const [value, setValue] = useState<number>(0);

  useEffect(() => {
    onChange(value);
  }, [value]);

  return (
    <div className="profile-navigation-container">
      <BottomNavigation
        showLabels
        value={value}
        className={(loading ? "unavailable " : "") + "profile-navigation-container__navigation"}
        onChange={(event: SyntheticEvent, newValue: number) => !loading && setValue(newValue)}
      >
        <BottomNavigationAction label="Persönliche Informationen" icon={<Person />} />
        <BottomNavigationAction label="Verknüpfungen" icon={<Cloud />} />
        <BottomNavigationAction label="Automaten" icon={<Inventory />} />
        <BottomNavigationAction label="Gruppen" icon={<Workspaces />} />
        <BottomNavigationAction label="Produkt-Ausnahmen" icon={<ProductionQuantityLimits />} />
        <BottomNavigationAction label="Einstellungen" icon={<Settings />} />
      </BottomNavigation>
    </div>
  );
};

export default ProfileNavigation;
