import {auth, db} from "../../config/firebase";

import {useState} from "react";

import {FormOut} from "../../utils/formUtils";
import {ProductException, ProductExceptionsData} from "../../hooks/useProductExceptions";

import FormRow from "./containers/FormRow";
import ButtonInput from "./inputs/ButtonInput";
import FormSection from "./containers/FormSection";
import TextInput from "./inputs/TextInput";
import {newDoc, setUserMultiple} from "../../utils/userUtils";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import {setDeepValue} from "../../utils/dbUtils";
import {deleteDoc, doc} from "firebase/firestore";
import {Delete} from "@mui/icons-material";

export type NewExceptionFormOut = FormOut<ProductException>;

export type ExceptionsFormOut = FormOut<ProductExceptionsData>;

interface Props {
  actionLoading?: boolean;
  exceptions?: ProductExceptionsData;
  onSubmit: (data: NewExceptionFormOut | ExceptionsFormOut) => void;
}

const ProductExceptionsForm = ({actionLoading, exceptions={}, onSubmit }: Props) => {

  const [exceptionsData, setExceptionsData] = useState<ProductExceptionsData>(exceptions);

  const [newExceptionPlu, setNewExceptionPlu] = useState<string>("");

  const setValue = (key: string, value: string, deepKey?: string) => {
    setExceptionsData(setDeepValue(exceptionsData, key, value, deepKey));
  };

  const checkNewException = async (): Promise<void> => {
    if(!newExceptionPlu) {
      throw new Error("Nicht alle erforderlichen Felder wurden ausgefüllt.");
    }
  };

  const checkExceptions = async (): Promise<void> => {
    let key: string;
    for(key in exceptionsData) {
      if(!exceptionsData[key].plu) {
        throw new Error("Nicht alle erforderlichen Felder wurden ausgefüllt.");
      }
    }
  };

  const out = {
    plu: newExceptionPlu
  };

  const saveNewException = async (): Promise<void> => {
    await newDoc("users/" + auth.currentUser?.uid + "/productExceptions", out);
    setNewExceptionPlu("");
  };

  const deleteException = async (key: string): Promise<void> => {
    await deleteDoc(doc(db, "users/" + auth.currentUser?.uid + "/productExceptions/" + key));
  };

  const updateExceptions = async (): Promise<void> => {
    await setUserMultiple(exceptionsData, "productExceptions");
  };

  const getNewExceptionOutData = (): NewExceptionFormOut => {
    return {
      data: out,
      check: checkNewException,
      save: saveNewException
    };
  };

  const updateExceptionsOutData = (): ExceptionsFormOut => {
    return {
      data: {},
      check: checkExceptions,
      save: updateExceptions
    };
  };

  const deleteExceptionOutData = (key: string): ExceptionsFormOut => {
    return {
      data: {},
      check: async () => {},
      save: async () => deleteException(key)
    };
  };

  return (
    <>
      <FormSection headline="Produkt-Ausnahme erstellen">
        <FormRow label="Artikelnummer">
          <TextInput
            disabled={actionLoading}
            label="Nummer"
            value={newExceptionPlu}
            onChange={(value: string) => setNewExceptionPlu(value)}
          />
        </FormRow>
        <ButtonInput
          actionLoading={actionLoading}
          text="Hinzufügen"
          onClick={() => onSubmit(getNewExceptionOutData())}
        />
      </FormSection>
      <FormSection headline="Produkt-Ausnahmen verwalten">

        <TableContainer>
          <Table className="form-table form-table--groups">
            <TableHead>
              <TableRow>
                <TableCell>Artikelnummer</TableCell>
                <TableCell>Optionen</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                Object.keys(exceptions).map((exceptionKey: string, index: number) => {
                  let foundOne = false;
                  return (
                    <TableRow key={exceptionKey}>
                      <TableCell>
                        <TextInput
                          label="Nummer"
                          defaultValue={exceptions[exceptionKey].plu}
                          onChange={(value: string) => setValue(exceptionKey, value, "plu")}
                        />
                      </TableCell>
                      <TableCell>
                        <ButtonInput
                          text={<Delete />}
                          endIcon={null}
                          onClick={() => onSubmit(deleteExceptionOutData(exceptionKey))}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })
              }
            </TableBody>
          </Table>
        </TableContainer>

        <ButtonInput
          actionLoading={actionLoading}
          text="Speichern"
          onClick={() => onSubmit(updateExceptionsOutData())}
        />
      </FormSection>
    </>
  );
};

export default ProductExceptionsForm;
