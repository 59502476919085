import {useState, useEffect, useRef} from "react";

import {generateFile} from "../config/cloudFunctions";

import { getStorage, ref, listAll, getMetadata, deleteObject, getDownloadURL, FullMetadata, StorageReference } from "firebase/storage";
import { DocumentData } from "firebase/firestore";
import { User } from "firebase/auth";

import { createInfo, FormInfo } from "../utils/formUtils";
import {useOnSnapshot} from "../hooks/useDatabase";
import {ProviderData, useProviders} from "../hooks/useProviders";
import {LoginData, useCredentials} from "../hooks/useCredentials";
import {useVendingMachines, useVendingMachinesAll, VendingMachinesData} from "../hooks/useVendingMachines";

import MainContainer from "../components/MainContainer";
import FileViewer, {FileInfoList} from "../components/home/FileViewer";
import GenerateFileForm, { GenerateFileFormOut } from "../components/forms/GenerateFileForm";
import FormContainer from "../components/forms/containers/FormContainer";

import { Typography } from "@mui/material";
import {GroupsData, useGroups} from "../hooks/useGroups";
import {ProductExceptionsData, useProductExceptions} from "../hooks/useProductExceptions";

interface Props {
  pageLoading: boolean;
  firebaseUser: User | null;
  user: DocumentData | null | undefined;
}

const Home = ({ pageLoading, user, firebaseUser }: Props) => {
  
  const storage = getStorage();

  const printContainerRef = useRef<HTMLIFrameElement>(null);
  const downloadTriggerRef = useRef<HTMLAnchorElement>(null);

  const processInfo: DocumentData | null | undefined = useOnSnapshot("processes", firebaseUser?.uid);

  const providers: ProviderData | undefined = useProviders();
  const credentials: LoginData | undefined = useCredentials(firebaseUser?.uid);
  const vendingMachines: VendingMachinesData | undefined = useVendingMachines(firebaseUser?.uid, credentials, false);
  const groups: GroupsData | undefined = useGroups(firebaseUser?.uid);

  const [loading, setLoading] = useState<boolean>(true);

  const [generateActionLoading, setGenerateActionLoading] = useState<boolean>(false);

  const [formInfo, setFormInfo] = useState<FormInfo>(createInfo());

  const [files, setFiles] = useState<FileInfoList>(Array(2).fill({}));

  const [currentPrintUrl, setCurrentPrintUrl] = useState<string>("");

  const isLoading: boolean = loading || pageLoading || !providers || !vendingMachines || !credentials;

  const getFiles = async (userId: string): Promise<Array<FullMetadata>> => {
    try {
      let fileData: Array<FullMetadata> = [];
      let file: StorageReference;
      for (file of (await listAll(ref(storage, userId))).items) {
        fileData.push(await getMetadata(file));
      }
      return fileData.sort((a, b) => (a.name < b.name) ? 1 : ((b.name < a.name) ? -1 : 0));
    }
    catch (error: any) {
      console.log(error);
      return [];
    }
  };

  const generate = async (fileFormData: GenerateFileFormOut): Promise<void> => {
    setGenerateActionLoading(true);
    try {
      await fileFormData.check();
      await generateFile({
        userData: user,
        providers: providers,
        vendingMachines: vendingMachines,
        credentials: credentials,
        selectedVendingMachines: fileFormData.data.selectedVending,
        mergeFiles: fileFormData.data.mergeFiles
      });
      const fileData: Array<FullMetadata> = await getFiles(firebaseUser?.uid || "");
      setFiles(fileData);
      setFormInfo(createInfo(true,"Datenblätter wurden erfolreich generiert.",10000));
    }
    catch (error: any) {
      setFormInfo(createInfo(false, error.message || "Es ist ein Fehler bei der Generierung aufgetreten.", 10000));
    }
    finally {
      setGenerateActionLoading(false);
    }
  };

  const getFileUrl = (fullPath: string): Promise<string> => {
    return new Promise<string>(async (resolve, reject) => {
      const xhr: XMLHttpRequest = new XMLHttpRequest();
      xhr.responseType = "blob";
      xhr.onload = () => {
        resolve(window.URL.createObjectURL(xhr.response));
      };
      xhr.open("GET", await getDownloadURL(ref(storage, fullPath)));
      xhr.send();
    });
  };

  const openFileDom = async (fullPath: string) => {
    window.open(await getFileUrl(fullPath), "_blank")?.focus();
  };

  const downloadFileDom = async (fullPath: string) => {
    downloadTriggerRef.current!.href = await getFileUrl(fullPath);
    downloadTriggerRef.current?.click();
  };

  const triggerPrint = async (fullPath: string) => {
    setCurrentPrintUrl(await getFileUrl(fullPath));
  };

  const printFileDom = () => {
    if(currentPrintUrl) {
      printContainerRef.current?.focus();
      printContainerRef.current?.contentWindow?.print();
    }
  };

  const deleteFile = async (fullPath: string): Promise<void> => {
    try {
      await deleteObject(ref(storage, fullPath));
      setFiles(
        files.filter((obj: FullMetadata | null) => {
          return obj?.fullPath !== fullPath;
        })
      );
      setFormInfo(createInfo(true, "Datenblatt wurde erfolgreich gelöscht.", 10000));
    }
    catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    let isSubscribed: boolean = true;
    (async () => {
      if(firebaseUser && user) {
        const fileData: FileInfoList = await getFiles(firebaseUser.uid);
        if(isSubscribed) {
          setFiles(fileData);
          setLoading(false);
        }
      }
    })();
    return () => {
      isSubscribed = false;
    };
  }, [user]);

  return (
    <MainContainer>
      <Typography variant="h5">Befüllvorschläge erstellen</Typography>

      <iframe
        ref={printContainerRef}
        src={currentPrintUrl}
        onLoad={() => printFileDom()}
        style={{display: "none"}}
      />

      <a
        ref={downloadTriggerRef}
        download=""
        target="_blank"
        style={{display: "none"}}
      />

      <FormContainer
        processInfo={processInfo}
        headline={"Befüllvorschläge erstellen"}
        description="Schnell und einfach Datenblätter Ihrer Automaten generieren, um diese unkompliziert in ihr Kassensystem einzupflegen."
        info={formInfo}
        hideInfo={true}
      >
        <GenerateFileForm
          loading={isLoading}
          actionLoading={generateActionLoading}
          vendingMachines={vendingMachines}
          providers={providers}
          groups={groups}
          groupManagement={user?.settings.groupManagement}
          files={files}
          onSubmit={(data: GenerateFileFormOut) => generate(data)}
        />
      </FormContainer>

      <FileViewer
        loading={isLoading}
        files={files}
        vendingMachines={vendingMachines}
        providers={providers}
        onViewClick={openFileDom}
        onDownloadClick={downloadFileDom}
        onPrintClick={triggerPrint}
        onDeleteClick={deleteFile}
      />
    </MainContainer>
  );
};

export default Home;
